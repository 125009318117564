import React, { useState, useEffect } from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import { graphql } from "gatsby"

import { FilterByQuery } from "../utils/FilterByQuery"
import { Divider } from "@chakra-ui/layout"
import PostGrid from "../components/global/PostGrid"

export default function Search({ location, data }) {
  const query = location.search
    .slice(3)
    .replace(/\+/g, " ")
    .replaceAll("ae", "æ")
    .replaceAll("oe", "ø")
    .replaceAll("aa", "å")

  const { allSanityPost, allSanityRecipes } = data
  const [posts, setPost] = useState([])

  useEffect(() => {
    const allResults = allSanityRecipes.nodes.concat(allSanityPost.nodes)

    setPost(FilterByQuery(allResults, query))
  }, [query, allSanityRecipes.nodes, allSanityPost.nodes])

  const [searchText, setSearchText] = useState("Søger...")

  useEffect(() => {
    setTimeout(() => {
      setSearchText("Søgningen gav desværre ingen resultater")
    }, 1000)
  }, [])

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={
          data.sanityPages.seo.seoTitle !== ""
            ? data.sanityPages.seo.seoTitle
            : "Gatrologik | Mad med mening"
        }
        description={
          data.sanityPages.seo.description !== ""
            ? data.sanityPages.seo.description
            : "Gatrologik | Mad med mening"
        }
      />

      <div className="titleWrapper">
        <h1>Søgeresultat for '{query}'</h1>

        <div className="spanLine"></div>
      </div>

      <Divider mb="40px" />
      {posts.length !== 0 ? (
        <PostGrid posts={posts} />
      ) : (
        <h2
          style={{ textAlign: "center", marginTop: "50px", marginBottom: 100 }}
        >
          {searchText}
        </h2>
      )}
      <Divider />
    </Layout>
  )
}

export const query = graphql`
  query searchQuery {
    allSanityPost(sort: { publishedAt: DESC }) {
      nodes {
        tags {
          label
        }
        title
        slug {
          current
        }
        image: mainImage {
          asset {
            gatsbyImageData(
              width: 370
              backgroundColor: "#f9f9f9"
              placeholder: NONE
            )
          }
        }
      }
    }
    allSanityRecipes(sort: { publishedAt: DESC }) {
      nodes {
        tags {
          label
        }
        title
        image: recipeImage {
          verticalImage {
            asset {
              gatsbyImageData(
                width: 370
                backgroundColor: "#f9f9f9"
                placeholder: NONE
              )
            }
          }
        }
        slug {
          current
        }
      }
    }

    sanityPages(id: { eq: "-45ee5e5b-9a09-5d50-8603-415ce58a276a" }) {
      seo {
        seoDescription
        seoTitle
      }
    }
  }
`
