export function FilterByQuery(allResults, query) {
  const newResults = []
  allResults.forEach(result => {
    const stringyfiedResult = JSON.stringify(result)
      .replace(/['"]+/g, " ")
      .toLowerCase()
      .replace(/\+/g, " ")

    if (stringyfiedResult.includes(query)) {
      newResults.push(result)
    }
  })
  return newResults
}
